import React from 'react';
import Nav from '@common/NavBar'
import Footer from '@common/Footer'
import MusicPlayer from '@common/MusicPlayer'
import { StateProvider } from "@global/StateContext";

export default function Layout({ children }) {
  return (
    <StateProvider>
      <div className="dummy hidden" />
      <Nav />
      <MusicPlayer />
      {children}
      <Footer />
    </StateProvider>
  );
}
