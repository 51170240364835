import React from "react";

const StateContext = React.createContext(null);

function StateProvider(props) {
  const [state, setState] = React.useState({
    web3: null,
    connectedAddress: null
  });

  const updateState = newState => {
    setState(newState);
  };

  return (
    <StateContext.Provider value={{ state, updateState }}>
      {props.children}
    </StateContext.Provider>
  );
}

export { StateContext, StateProvider };
