import React from "react";
import { Link } from "gatsby"

export default function NavBar(props) {

  const imgLinks = {
    imgLogo: "/images/icons/logo.svg",
    imgDiscord: "/images/icons/iconDiscord.png",
    imgTwitter: "/images/icons/iconTwitter.png",
    imgOpenSea: "/images/icons/iconOpenSea.png",
    imgLooksRare: "/images/icons/iconLooksRare.png",
  }

  const getWindowLocationSearch = () => {
    return typeof window !== 'undefined' ? window.location.search : '';
  }

  const TextLink = (props) => {
    let extraClasses = props.extraClasses || '';
    return (
      <Link className={`flex h-full justify-center items-center px-2 lg:px-3 active:scale-105 duration-150 ${extraClasses}`} to={props.url + getWindowLocationSearch()}>{props.text}</Link>
    );
  }

  const IconLink = (props) => {
    let extraClasses = props.extraClasses || '';
    return (
      <a className={`flex justify-center items-center px-3 ${extraClasses}`} href={props.url + getWindowLocationSearch()} target="_blank" rel="noopener noreferrer">
        <img src={props.icon} className="w-6 pointer-events-none" />
      </a>
    );
  }

  return (
    <>
      <div className="w-full h-12" />
      <div id="nav" className="fixed inset-0 h-12 w-full z-50 font-extrabold text-white flex flex-wrap text-sm lg:text-base hover:backdropBlur">
        <Link id="topLogo" className="inset-0 w-40 h-12 flex justify-center items-center hidden lg:flex" to={"/" + getWindowLocationSearch()}>
          <img src={imgLinks.imgLogo} className="w-10/12 pointer-events-none"/>
        </Link>
        <div className="grow hidden lg:flex" />
        <div className="options flex flex-wrap items-stretch overflow-hidden w-full lg:w-auto lg:justify-end">
          <TextLink url="/" text="HOME" />
          <TextLink url="/mine" text="MINE"/>
          <TextLink url="/mystics" text="MYSTICS"/>
          <TextLink url="/beatz" text="BEATZ"/>
          <TextLink url="/bakery" text="BAKERY" extraClasses="hidden lg:flex"/>
          <div className="spacer grow flex lg:hidden" />
          <IconLink url="https://discord.gg/kikomints" icon={imgLinks.imgDiscord} />
          <IconLink url="https://www.twitter.com/kikomints" icon={imgLinks.imgTwitter} />
        </div>
      </div>
    </>
  );
}
