import React from "react";
// import "./style.css";

export default function Footer() {
    return (
      <div id="footer" className={`flex bg-[rgba(0,0,0,.5)] w-full h-12 text-center items-center justify-center z-50 text-[rgba(255,255,255,.25)]`}>
        <p>&copy; 2023. All Rights Reserved.</p>
      </div>
    );
}
