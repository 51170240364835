import React, { Component } from 'react'

import ReactPlayer from 'react-player'
import Track1 from './tracks/beanBeatz.mp3'
import Track2 from './tracks/lunarArrival.mp3'
import Track3 from './tracks/irlBreak.mp3'
import Track4 from './tracks/nostalgicApe.mp3'

import imgContainer from "./images/container.png"
import imgPlay from "./images/play.png"
import imgPause from "./images/pause.png"
import imgNext from "./images/next.png"
import imgBack from "./images/back.png"

let tracks = [
  {url: Track1, title: 'bean beatz', artist: 'josta'},
  {url: Track2, title: 'lunar arrival', artist: 'ghost texture'},
  {url: Track3, title: 'irl break', artist: 'juan mandagie'},
  {url: Track4, title: 'nostalgic ape', artist: 'josta'},
]

let currentTrack = Math.floor(Math.random() * tracks.length)

class App extends Component {

  state = {
    url: tracks[currentTrack].url,
    title: tracks[currentTrack].title,
    artist: tracks[currentTrack].artist,
    pip: false,
    playing: false,
    controls: false,
    light: false,
    volume: 0.8,
    muted: false,
    played: 0,
    loaded: 0,
    duration: 0,
    playbackRate: 1.0,
    loop: false,
  }

  load = url => {
    this.setState({
      url,
      played: 0,
      loaded: 0,
      pip: false,
    })
  }

  handlePlayPause = () => {
    this.setState({ playing: !this.state.playing })
  }

  handlePlay = () => {
    this.setState({ playing: true })
  }

  handlePause = () => {
    this.setState({ playing: false })
  }

  handleEnded = () => {
    currentTrack = currentTrack < tracks.length - 1? currentTrack + 1 : 0
    this.setState({ url: tracks[currentTrack].url})
    this.setState({ artist: tracks[currentTrack].artist})
    this.setState({ title: tracks[currentTrack].title})
    this.setState({ playing: true })
  }

  handleNext = () => {
    currentTrack = currentTrack < tracks.length - 1? currentTrack + 1 : 0
    this.setState({ url: tracks[currentTrack].url})
    this.setState({ artist: tracks[currentTrack].artist})
    this.setState({ title: tracks[currentTrack].title})
  }

  handleBack = () => {
    currentTrack = currentTrack <= 0? tracks.length - 1 : currentTrack - 1
    this.setState({ url: tracks[currentTrack].url})
    this.setState({ artist: tracks[currentTrack].artist})
    this.setState({ title: tracks[currentTrack].title})
  }

  ref = player => {
    this.player = player
  }

  render() {
    const { url, title, artist, playing, controls, light, volume, muted, loop, played, loaded, duration, playbackRate, pip } = this.state
    const SEPARATOR = ' · '
    return (
      <div id="audioPlayer" className={`fixed bottom-3 left-3 h-16 bg-cover bg-center bg-no-repeat overflow-hidden z-50 rounded-xl border-2 border-solid border-[black] transition-[width] duration-1000 text-white ` + (playing ? 'w-80' : 'brightness-75 w-36')} style={{ backgroundImage: `url(${imgContainer})` }}>
        <ReactPlayer
          ref={this.ref}
          className='react-player'
          width='100%'
          height='100%'
          url={url}
          title={title}
          artist={artist}
          pip={pip}
          playing={playing}
          controls={controls}
          light={light}
          loop={loop}
          playbackRate={playbackRate}
          volume={volume}
          muted={muted}
          onReady={() => console.log('onReady')}
          onStart={() => console.log('onStart')}
          onPlay={this.handlePlay}
          onEnablePIP={this.handleEnablePIP}
          onDisablePIP={this.handleDisablePIP}
          onPause={this.handlePause}
          onBuffer={() => console.log('onBuffer')}
          onPlaybackRateChange={this.handleOnPlaybackRateChange}
          onSeek={e => console.log('onSeek', e)}
          onEnded={this.handleEnded}
          onError={e => console.log('onError', e)}
          onProgress={this.handleProgress}
          onDuration={this.handleDuration}
        />
      <div id="audioPlayerTitle" className={`absolute top-1.5 left-2.5 font-player text-2xl ` + (playing ? '' : 'hidden')}>
          {title}
        </div>
        <div id="audioPlayerArtist" className={`absolute top-9 left-3 text-xs font-bold font-sans ` + (playing ? '' : 'hidden')}>
          {artist}
        </div>
        <div id="audioControls" className={`absolute top-0 r-2.5 h-full ` + (playing ? 'right-2.5' : 'left-2.5')}>
          <button className={`bg-no-repeat bg-center w-8 h-full bg-contain active:scale-125 ` + (playing ? '' : 'hidden')} style={{backgroundImage: `url(${imgBack})`}} onClick={this.handleBack}></button>
          <button className={`bg-no-repeat bg-center w-8 h-full bg-contain active:scale-125 `} style={{backgroundImage: playing ? `url(${imgPause})` : `url(${imgPlay})`}} onClick={this.handlePlayPause}></button>
          <button className={`bg-no-repeat bg-center w-8 h-full bg-contain active:scale-125 ` + (playing ? '' : 'hidden')} style={{backgroundImage: `url(${imgNext})`}} onClick={this.handleNext}></button>
        </div>
      </div>
    )
  }
}

export default App
